.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.button-container {
  display: flex;
  justify-content: space-around;
  width: 300px;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.left-button {
  background-color: #61dafb;
  border: none;
  color: white;
}

.right-button {
  background-color: #282c34;
  border: none;
  color: white;
}

.displayed-image {
  max-width: 300px;
  margin-bottom: 20px;
}
